import React from 'react';

import ScrollToTop from './components/ScrollToTop';
import Projects from './components/Projects';
import Partner from './components/Partner';
import Footer from './components/Footer';
import Contact from './components/Contact';
import Intro from './components/Intro';
import About from './components/About';
import Team from './components/Team';
import ContactForm from './components/ContactForm';
import Showcase from './components/Showcase';
import Facts from './components/Facts';
import Pakete from './components/Pakete';
import Leistungen from './components/Leistungen';
import Testimonial from './components/Testimonial';
import Impressum from './components/Impressum';
import Privacy from './components/Privacy';
import TextImage from './components/TextImage';
import MenuBar from './components/MenuBar';
import Seo from './components/Seo';
import Modal from './components/Modal';
import 'normalize.css';
import './assets/css/main.scss';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Modal/>
      <Router>
        <ScrollToTop>
        <Switch>
          <Route exact path="/">
            <Seo socialImage='https://cms.goldenlegprojects.com/header.jpg' pageTitle="Startseite" siteTitle="goldenleg Projects GmbH | Eventagentur Schwerin" pageDescription="Überlassen Sie uns die Eventplanung. Überzeugen Sie sich selbst! ✅ 100% zufriedene Kunden ✅ Mit mehr als 20 Jahre Erfahrung"/>
            <Intro background="https://cms.goldenlegprojects.com/header.webp" title="Jeder Event ein Volltreffer" text="Macht aus eurem Ereignis ein echtes Erlebnis für eure Gäste an das sie sich ihr Leben lang erinnern - mit Goldenleg Projects"/>
            <TextImage headline="Erfolgreiche Events für euch, eure Firmen, eure Liebsten und den ganzen Norden"/>
            <Testimonial background="https://cms.goldenlegprojects.com/rezension-1.jpg" headline="Wir helfen euch von der Planung bis zur Nachspeise "/>
            <Footer/>
          </Route>
          <Route exact path="/privatveranstaltungen">
            <Seo socialImage='https://cms.goldenlegprojects.com/header.jpg' pageTitle="Privatveranstaltungen" siteTitle="goldenleg Projects GmbH | Eventagentur Schwerin" pageDescription="Egal ob Geburtstag, Junggesellenabschieb oder Hochzeit. Wir übernehmen die Planung für Ihre nächste Feier! Jetzt kostenfrei informieren!"/>
            <Intro background="https://cms.goldenlegprojects.com/header-privatveranstaltungen.jpg" title="Macht besondere Ereignisse zu unvergesslichen Erlebnissen" text="Ereignisse werden zu echten Erlebnissen für eure Gäste an die sie sich ihr Leben lang erinnern – mit Goldenleg Projects"/>
            <Pakete headline="Das perfekte Erlebnis für jeden eurer besonderen Anlässe" />
            <Leistungen headline="Profileistungen für perfekte Details, die eure Gäste überraschen"/>
            <Testimonial background="https://cms.goldenlegprojects.com/rezension-1.jpg" headline="Wir helfen euch von der Planung bis zur Nachspeise "/>
            <Footer/>
          </Route>
          <Route exact path="/firmenevents">
            <Seo socialImage='https://cms.goldenlegprojects.com/header.jpg' pageTitle="Firmenevents" siteTitle="goldenleg Projects GmbH | Eventagentur Schwerin" pageDescription="Wir übernehmen die Planung Ihrer nächsten Firmenfeier! Von Eventraum bis zum Buffet – wir sind Ihr Ansprechpartner für einzigartige Events."/>
            <Intro background="https://cms.goldenlegprojects.com/header-firmenevents.jpg" title="Wo wir den Fuß in die Tür kriegen wird's gold für unsere Gäste" text="Goldenleg Projects ist Gold im Gastro- und Eventbereich - lerne uns kennen und was uns so golden macht."/>
            <Pakete paket="Firma" headline="Das perfekte Erlebnis für jeden eurer besonderen Anlässe"/>
            <Leistungen leistung="Firma" headline="Profileistungen für perfekte Details, die eure Gäste überraschen"/>
            <Footer/>
          </Route>
          <Route exact path="/fuer-veranstalter">
            <Seo socialImage='https://cms.goldenlegprojects.com/header.jpg' pageTitle="Für Veranstalter" siteTitle="goldenleg Projects GmbH | Eventagentur Schwerin" pageDescription="Zusammen mit Ihnen planen wir Ihr nächstes Event. Zusammen mit unseren Partnern realisieren wir Ihr Event. Von Bühnenbau bis High-End-Equipment!"/>
            <Intro background="https://cms.goldenlegprojects.com/header-veranstalter.jpg" title="Macht besondere Ereignisse zu unvergesslichen Erlebnissen" text="Ereignisse werden zu echten Erlebnissen für eure Gäste an die sie sich ihr Leben lang erinnern – mit Goldenleg Projects"/>
            <Leistungen leistung="Veranstalter" headline="Profileistungen für perfekte Details, die eure Gäste überraschen"/>
            <Testimonial background="https://cms.goldenlegprojects.com/rezension-1.jpg" headline="Wir helfen euch von der Planung bis zur Nachspeise "/>
            <Footer/>
          </Route>
          <Route exact path="/eigenmarken">
            <Seo socialImage='https://cms.goldenlegprojects.com/header.jpg' pageTitle="Eigenmarken" siteTitle="goldenleg Projects GmbH | Eventagentur Schwerin" pageDescription="Mit unseren hauseigenen Events bringen wir den Norden zum tanzen. Von Hamburg bis Berlin – wir waren schon überall!"/>
            <Intro background="https://cms.goldenlegprojects.com/eigenmarken-header.jpg" title="Wir bringen den Norden zusammen" text="Wir, unsere Freunde und unsere Familien sind im Norden daheim. Für sie tun wir was wir tun – Erlebnisse kreieren, die unsere Heimat noch lebenswerter machen."/>
            <Projects/>
            <Footer/>
          </Route>
          <Route exact path="/impressum">
            <Seo socialImage='https://cms.goldenlegprojects.com/header.jpg' pageTitle="Impressum" siteTitle="goldenleg Projects GmbH | Eventagentur Schwerin" pageDescription="Erfolgreiche Events für euch, eure Firmen, eure Liebsten und den ganzen Norden."/>
            <MenuBar/>
            <Impressum/>
            <Footer/>
          </Route>
          <Route exact path="/datenschutzerklaerung">
            <Seo socialImage='https://cms.goldenlegprojects.com/header.jpg' pageTitle="Impressum" siteTitle="goldenleg Projects GmbH | Eventagentur Schwerin" pageDescription="Erfolgreiche Events für euch, eure Firmen, eure Liebsten und den ganzen Norden."/>
            <MenuBar/>
            <Privacy/>
            <Footer/>
          </Route>
          <Route exact path="/ueber-uns">
            <Seo socialImage='https://cms.goldenlegprojects.com/header.jpg' pageTitle="Über Uns" siteTitle="goldenleg Projects GmbH | Eventagentur Schwerin" pageDescription="Erfahren Sie mehr über die goldenleg Projects GmbH und finden Sie Ihren Ansprechpartner!"/>
            <Intro background="https://cms.goldenlegprojects.com/header-privatveranstaltungen.jpg" title="Wo wir den Fuß in die Tür kriegen wird's Gold für unsere Gäste" text="Goldenleg Projects ist Gold im Gastro- und Eventbereich - lerne uns kennen und was uns so golden macht."/>
            <Team/>
            <Facts/>
            <Showcase/>
            <ContactForm/>
            <Contact title="Sprecht mit uns über euer neues Projekt" name="Issa Saleh" position="Projektleiter" telephone="01522 2534825" image="https://cms.goldenlegprojects.com/issa-saleh150x150.jpg" captionTitle="Issa Saleh" captionText="Issa ist Gastro- und Event-Manager bei Goldenleg Projects" contentImage="https://cms.goldenlegprojects.com/issa-saleh.jpg"/>
            <Footer/>
          </Route>
          <Route path="*">
            <MenuBar/>
            <section id="content" className="line">
                <div className="container">
                    <h1>Fehler 404</h1>
                    <p>Seite konnte nicht gefunden werden. Versuchen Sie es später erneut.</p>
                    <Link className="btn standard" to="/">Startseite</Link>
                </div>
            </section>
            <Footer/>
          </Route>
        </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
