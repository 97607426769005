import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';

class TextImageItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: this.props.image,
            title: this.props.title,
            subline: this.props.subline,
            description: this.props.description,
            buttonTitle: this.props.buttonTitle,
            link: this.props.link
        }
    }
    render() { 
        return (
            <div className="content-wrapper">
                <div className="image-content">
                    <LazyLoadImage className="img-responsive" alt={this.props.title} src={this.props.image}/>
                </div>
                <div className="text-content">
                    <div className="text-inner">
                        <p className="subline">{this.props.subline}</p>
                        <h2>{this.props.title}</h2>
                        <p>{this.props.description}</p>
                        <p>
                            <Link className="btn secondary" to={this.state.link}>{this.props.buttonTitle}</Link>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default TextImageItem;