import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import '../assets/css/_footer.scss';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.socialLinks = [
            { id: 1, title: "Facebook", link: "https://www.facebook.com/goldenlegprojects" },
            { id: 2, title: "Instagram", link: "https://www.instagram.com/goldenlegprojects" }
        ];
        this.footerLinks = [
            { id: 1, title: "Impressum", link: "/impressum" },
            { id: 2, title: "Datenschutzerklärung", link: "/datenschutzerklaerung" }
        ]
        this.state = {  }
    }
    render() { 
        return (
            <footer id="footer" className="line">
                <div className="container-fluid">
                    <div className="row justify-between">
                        <div className="footer-widget">
                            <address>
                                <p>goldenleg Projects GmbH<br/><br/>

                                Wiesenhof 1<br/>
                                19061 Schwerin<br/>
                                Deutschland<br/><br/>

                                <a href="mailto:contact@goldenlegprojects.com" title="Jetzt Nachricht schreiben">contact@goldenlegprojects.com</a></p>
                            </address>
                        </div>
                        <div className="footer-widget social">
                            {this.socialLinks.map((socialLink) =>
                            <a key={socialLink.id} href={socialLink.link} title={socialLink.title}>
                                {(socialLink.title == "Instagram" ?
                                    ( 
                                        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path d='M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z'/><path d='M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0,1,377.33,162.67Z'/><path d='M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z'/></svg>
                                    )
                                    : (
                                        <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512'><path d='M480,257.35c0-123.7-100.3-224-224-224s-224,100.3-224,224 c0,111.8,81.9,204.47,189,221.29V322.12h-56.89v-64.77H221v-49.36c0-56.13,33.45-87.16,84.61-87.16c24.51,0,50.15,4.38,50.15,4.38 v55.13h-28.26c-27.81,0-36.51,17.26-36.51,35v42.02h62.12l-9.92,64.77h-52.2v156.53C398.1,461.85,480,369.18,480,257.35L480,257.35z '/></svg>
                                    )
                                )}
                            </a>
                            )}
                        </div>
                        <div className="footer-widget">
                            <ul className="menu">
                                {this.footerLinks.map((footerLinks) =>
                                <li key={footerLinks.id}>
                                    <Link to={footerLinks.link}>{footerLinks.title}</Link>
                                </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default Footer;