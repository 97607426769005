import React, { Component } from 'react';
import '../assets/css/_showcase.scss';

class Showcase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logos : [
                { id: 1, url: "https://cms.goldenlegprojects.com/sky-winter-cafe-logo-black.svg", alt: "Sky Winter Café", link: "https://skywintercafe.de/" },
                { id: 2, url: "https://cms.goldenlegprojects.com/gebru%CC%88der-saleh-logo.svg", alt: "Gebrüder Saleh Sprachenservice", link: "https://gebrueder-saleh.de/" }
            ]
        }
    }
    render() { 
        return (
            <section id="showcase" className="line">
                <div className="container">
                    <h2>Menschen zu verbinden – unsere Mission</h2>
                    <p>Goldenleg Projects steht für ein kompaktes Universum an Unternhemen, mit dem immer gleichen Grundgedanken: Menschen zu verbinden.</p>
                    <div className="logo-wrapper">
                        
                        {this.state.logos.map( (logo) =>
                            <div key={logo.id} className="logo-item">
                                <a href={logo.link} title={logo.alt} target="_blank">
                                    <img className="img-responsive" src={logo.url} alt={logo.alt}/>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Showcase;