import React, { Component } from 'react';
import ServiceItem from './ServiceItem';
import '../assets/css/_services.scss';

class Services extends Component {
    constructor(props) {
        super(props);
        this.boxes = this.props.boxes;
        this.state = {  }
    }
    render() { 
        return (
            <div className="service-wrapper">
                {this.boxes.map( (box) =>
                    <ServiceItem key={box.title} title={box.title} description={box.description} image={box.image}/>
                )}
            </div>
        );
    }
}
 
export default Services;