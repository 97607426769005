import React, { Component } from 'react';
import '../assets/css/_about.scss';

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subTitle: this.props.subTitle,
            title: this.props.title,
            text: this.props.text,
            buttonText: this.props.buttonText,
            buttonLink: this.props.buttonLink
        }
    }
    render() { 
        return (
            <section id="about" className="line" style={{background: "url('http://placehold.it/1920x1080') no-repeat center center / cover"}}>
                <div className="container-fluid">
                    <div className="half">
                        <h6>{this.state.subTitle}</h6>
                        <h2>{this.state.title}</h2>
                        <p>{this.state.text}</p>
                        <p>
                            <a href={this.state.buttonLink} className="btn standard">{this.state.buttonText}</a>
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default About;