import React, { Component } from 'react';
import '../assets/css/_projects.scss';

class Projects extends Component {
    constructor(props) {
        super(props);
        this.headline = "Unsere Eigenmarken – eine Welt an spannenden Erlebniss-Konzepten";
        this.projects = [
            { id: 1, title: "Sky Café", image: [{ url: "https://cms.goldenlegprojects.com/skybeachclub.jpg", alt: "Projekt" }] },
            { id: 2, title: "Mazar", image: [{ url: "https://cms.goldenlegprojects.com/placeholder.jpg", alt: "Projekt" }] },
            { id: 3, title: "Place2Be Festival", image: [{ url: "https://cms.goldenlegprojects.com/placeholder.jpg", alt: "Projekt" }] },
            { id: 4, title: "The Place Festival", image: [{ url: "https://cms.goldenlegprojects.com/the-place.jpg", alt: "Projekt" }] },
            { id: 5, title: "Osterknaller", image: [{ url: "https://cms.goldenlegprojects.com/osterknaller.jpg", alt: "Projekt" }] },
            { id: 6, title: "90er-Knaller", image: [{ url: "https://cms.goldenlegprojects.com/nachtderrevivals.jpg", alt: "Projekt" }] },
            { id: 7, title: "Nacht der Revivals", image: [{ url: "https://cms.goldenlegprojects.com/nachtderrevivals.jpg", alt: "Projekt" }] },
            { id: 8, title: "Miami Wittenburg", image: [{ url: "https://cms.goldenlegprojects.com/placeholder.jpg", alt: "Projekt" }] },
            { id: 9, title: "Flame", image: [{ url: "https://cms.goldenlegprojects.com/flame.jpg", alt: "Projekt" }] },
            { id: 10, title: "Tivoli", image: [{ url: "https://cms.goldenlegprojects.com/placeholder.jpg", alt: "Projekt" }] },
            { id: 11, title: "Achteck", image: [{ url: "https://cms.goldenlegprojects.com/placeholder.jpg", alt: "Projekt" }] },
            { id: 12, title: "Klangwunder", image: [{ url: "https://cms.goldenlegprojects.com/placeholder.jpg", alt: "Projekt" }] },
            { id: 13, title: "Schwerin tanzt.", image: [{ url: "https://cms.goldenlegprojects.com/placeholder.jpg", alt: "Projekt" }] },
            { id: 14, title: "MS KOI", image: [{ url: "https://cms.goldenlegprojects.com/ms-koi.jpg", alt: "Projekt" }] }
        ];
    }
    render() { 
        return (
            <section id="projects" className="line">
                <div className="container">
                    <div className="headline center">
                    <h2>{this.headline}</h2>
                    </div>
                    <div className="projects-wrapper">
                        {this.projects.map( project =>
                            <figure key={project.id} className="projects-item">
                                <img className="img-responsive" src={project.image[0].url} alt={project.image[0].alt}/>
                                <figcaption>
                                    <h2>{project.title}</h2>
                                    <p>{project.excerpt}</p>
                                </figcaption>
                            </figure>
                        )}
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Projects;