import React, { Component } from 'react';
import TextImageItem from './TextImageItem';

import '../assets/css/_textimage.scss';

class TextImage extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <section id="text-image" className="line">
                <div className="container">
                    <div className="headline center">
                        <h2>{this.props.headline}</h2>
                    </div>
                    <TextImageItem subline="Events für Privat" title="Einmalige Erlebnisse und unvergessliche Erinnerungen" description="Abibälle, Jugendweihen, Geburtstage – diese Anlässe gibt es nur ein Mal. So besonders sie sind, so besonders sollte ihr Event sein. Wir helfen euch dabei sie unvergesslich zu machen." buttonTitle="Event starten" link="/privatveranstaltungen" image="https://cms.goldenlegprojects.com/events-fuer-privat.jpg" />
                    <TextImageItem subline="Events für Firmen" title="Erfolgreich punkten bei Angestellten, Partnern und Kunden" description="Stellt sicher, dass euer Firmen-Event zu einem perfekten Erlebnis für eure Gäste wird mit Firmenevents vom Gastro- und Eventprofi." buttonTitle="Firmenevent starten" link="/firmenevents" image="https://cms.goldenlegprojects.com/events-fuer-firmen.jpg" />
                    <TextImageItem subline="Veranstalter-Support" title="Einmalige Erlebnisse und unvergessliche Erinnerungen" description="Erhaltet Profi-Support für euren Event – von High-End-Equipment über Künstler-Booking bis hin zur Vermarktungsunterstüzung. Wir feiern euch." buttonTitle="Veranstaltung starten" link="/fuer-veranstalter" image="https://cms.goldenlegprojects.com/veranstalter-support.jpg" />
                </div>
            </section>
        );
    }
}
 
export default TextImage;