import React, { Component } from 'react';
import {Helmet} from "react-helmet";

class Seo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageTitle: props.pageTitle,
            pageDescription: props.pageDescription,
            siteTitle: props.siteTitle,
            socialImage: props.socialImage
        }
    }
    componentWillReceiveProps(nextProps) {
        this.setState(
            {
                pageTitle: nextProps.pageTitle,
                pageDescription: nextProps.pageDescription,
                siteTitle: nextProps.siteTitle,
                socialImage: nextProps.socialImage
            }
        );
    }
    componentDidUpdate(prevProps) {
        if(prevProps.pageTitle !== this.props.pageTitle) {
            this.setState(
                {
                    pageTitle: this.props.pageTitle,
                    pageDescription: this.props.pageDescription,
                    siteTitle: this.props.siteTitle,
                    socialImage: this.props.socialImage
                }
            );
        }
    }
    render() { 
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{this.state.pageTitle} – {this.state.siteTitle}</title>
                <link rel="canonical" href="https://goldenlegprojects.com/" />
                <meta name="description" content={this.state.pageDescription}/>
                <meta name="robots" content="index, follow"/>
                <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
	            <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />

                <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico"></link>

                <meta property="og:locale" content="de_DE" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={this.state.pageTitle + ' - ' + this.state.siteTitle} />
                <meta property="og:description" content={this.state.pageDescription} />
                <meta property="og:url" content="https://goldenlegprojects.com/" />
                <meta property="og:site_name" content="godenleg Projects GmbH" />
                <meta property="og:image" content={this.state.socialImage} />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="627" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={this.state.pageTitle + ' - ' + this.state.siteTitle} />
                <meta name="twitter:description" content={this.state.pageDescription} />
                <meta name="twitter:image" content={this.state.socialImage} />

                <link rel="apple-touch-icon" sizes="57x57" href="https://cms.goldenlegprojects.com/apple-icon-57x57.png"/>
                <link rel="apple-touch-icon" sizes="60x60" href="https://cms.goldenlegprojects.com/apple-icon-60x60.png"/>
                <link rel="apple-touch-icon" sizes="72x72" href="https://cms.goldenlegprojects.com/apple-icon-72x72.png"/>
                <link rel="apple-touch-icon" sizes="76x76" href="https://cms.goldenlegprojects.com/apple-icon-76x76.png"/>
                <link rel="apple-touch-icon" sizes="114x114" href="https://cms.goldenlegprojects.com/apple-icon-114x114.png"/>
                <link rel="apple-touch-icon" sizes="120x120" href="https://cms.goldenlegprojects.com/apple-icon-120x120.png"/>
                <link rel="apple-touch-icon" sizes="144x144" href="https://cms.goldenlegprojects.com/apple-icon-144x144.png"/>
                <link rel="apple-touch-icon" sizes="152x152" href="https://cms.goldenlegprojects.com/apple-icon-152x152.png"/>
                <link rel="apple-touch-icon" sizes="180x180" href="https://cms.goldenlegprojects.com/apple-icon-180x180.png"/>
                <link rel="icon" type="image/png" sizes="192x192"  href="https://cms.goldenlegprojects.com/android-icon-192x192.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="https://cms.goldenlegprojects.com/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="96x96" href="https://cms.goldenlegprojects.com/favicon-96x96.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="https://cms.goldenlegprojects.com/favicon-16x16.png"/>
                <link rel="manifest" href="https://cms.goldenlegprojects.com/manifest.json"/>
                <meta name="msapplication-TileColor" content="#ffffff"/>
                <meta name="msapplication-TileImage" content="https://cms.goldenlegprojects.com/ms-icon-144x144.png"/>
                <meta name="theme-color" content="#ffffff"></meta>
            </Helmet>
        );
    }
}
 
export default Seo;