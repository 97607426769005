import React, { Component } from 'react';
import '../assets/css/_contact.scss';

class Contact extends Component {
    constructor(props) {
        super(props);
        this.content = {
            title: this.props.title,
            name: this.props.name,
            position: this.props.position,
            telephone: this.props.telephone,
            image: this.props.image,
            contentImage: this.props.contentImage,
            captionTitle: this.props.captionTitle,
            captionText: this.props.captionText
        }
        this.state = {  }
    }
    render() { 
        return (
            <section id="contact">
                <div className="container-fluid">
                    <div className="row align-center">
                        <div className="contact-image grid">
                            <img className="img-responsive" src={this.content.contentImage} alt={ (this.content.name) + ' Hintergrundbild'}/>
                            <div className="caption">
                                <p><strong>{this.content.captionTitle}</strong></p>
                                <p>{this.content.captionText}</p>
                            </div>
                        </div>
                        <div className="contact-actions grid">
                            <h2>{this.props.title}</h2>
                            <div className="contact-person">
                                <div className="contact-profileImage">
                                    <img src={this.content.image} alt={ (this.content.name) + ' Bild'}/>
                                </div>
                                <div className="contact-info">
                                    <div className="contact-name">
                                        <h3>{this.content.name}</h3>
                                    </div>
                                    <div className="contact-position">
                                        <p>{this.content.position}</p>
                                    </div>
                                    <div className="contact-telephone">
                                        <a href={'tel:' + (this.content.telephone)} title="Jetzt anrufen">{this.content.telephone}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Contact;