import React, { Component } from 'react';
import '../assets/css/_modal.scss';

class Modal extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        modal: true
    }
    closeModal(e) {
        e.preventDefault();
        this.setState({ modal: !this.state.modal });
    }
    render() { 
        return (
            (this.state.modal) ? 
            <div className="modalWrapper">
                <div className="modalInner">
                    <h2>Wo möchten Sie hin?</h2>
                    <div className="row">
                        <div className="col">
                            <a className="btn standard" href="https://gebrueder-saleh.de/" target="_blank">Dolmetscheragentur</a>
                        </div>
                        <div className="col">
                            <a className="btn standard beachclub" href="https://beachclub-schwerin.de/" target="_blank">Beachclub & Catering</a>
                        </div>
                        <div className="col">
                            <a className="btn standard" href="#" onClick={(e) => this.closeModal(e)} target="_blank">Eventagentur</a>
                        </div>
                    </div>
                </div>
            </div>
            :
            null
        );
    }
}
 
export default Modal;