import React, { Component } from 'react';
import Services from './Services';

class Leistungen extends Component {
    constructor(props) {
        super(props);
        if( props.leistung == 'Firma' ) {
            this.leistungen = [
                { id: 1, title: "Shuttle-Service", description: "Wir bringen euch pünktlich zum Event und sicher nach Hause.", image: "https://cms.goldenlegprojects.com/shuttle-service.jpg" },
                { id: 2, title: "DJ-Booking", description: "Wir stellen erfahrene DJs, die euer Event anheizen, bespielen und auf Wunsch auch moderieren.", image: "https://cms.goldenlegprojects.com/dj-booking.jpg" },
                { id: 3, title: "Catering", description: "Essen und Getränke ganz nach euren Wünschen: Snacks, BBQ, Dinner und vieles mehr.", image: "https://cms.goldenlegprojects.com/catering.jpg" },
                { id: 4, title: "Getränke-Pakete", description: "Für jede Feier das richtige Paket und die richtige Menge – passend zu jedem Budget.", image: "https://cms.goldenlegprojects.com/getraenke-paket.jpg" },
                { id: 5, title: "Dekoration", description: "Wir schmücken euer Event von der Tisch-Deko bis zur gesamten Raumgestaltung – je nach Wunsch.", image: "https://cms.goldenlegprojects.com/dekoration.jpg" },
                { id: 5, title: "technisches Equipment", description: "Beamer, Mikros, Lautsprecher, Musik, Licht – wir organisieren alles.", image: "https://cms.goldenlegprojects.com/technisches-equipment.jpg" },
                { id: 5, title: "Security", description: "Wir sorgen bei Bedarf für zusätzliche Sicherheit.", image: "https://cms.goldenlegprojects.com/security.jpg" },
                { id: 5, title: "Hygiene-Konzept", description: "Mit unseren Konzepten könnt ihr euch trotz Corona sicher treffen.", image: "https://cms.goldenlegprojects.com/hygiene-konzept.jpg" }
            ]
        } else if( props.leistung == 'Veranstalter' ) {
            this.leistungen = [
                { id: 1, title: "Bühnenbau", description: "Wir bauen Bühnen in jeder Größe und mit jedem Sonderwunsch.", image: "https://cms.goldenlegprojects.com/buehnenbau.jpg" },
                { id: 2, title: "Sicherheit", description: "Für jede Veranstaltung das richtige Sicherheits-Konzept, das die Behörden gerne abnehmen.", image: "https://cms.goldenlegprojects.com/sicherheit.jpg" },
                { id: 3, title: "Social-Media-Beratung", description: "Wir bieten Support für die neuen Medien, damit ihr noch mehr Menschen erreicht.", image: "https://cms.goldenlegprojects.com/social-media-beratung.jpg" },
                { id: 4, title: "Artist-Booking", description: "Nutzt unseren großen Pool an erfahrenen Djs und bekannten Acts.", image: "https://cms.goldenlegprojects.com/artist-booking.jpg" },
                { id: 5, title: "High-End-Equipment", description: "Wir installieren und kalibrieren professionelle Soundsysteme von Void.", image: "https://cms.goldenlegprojects.com/high-end-equipment.jpg" }
            ]
        } else {
            this.leistungen = [
                { id: 1, title: "DJ-Booking", description: "Wir stellen erfahrene DJs, die euer Event anheizen, bespielen und auf Wunsch auch moderieren.", image: "https://cms.goldenlegprojects.com/dj-booking.jpg" },
                { id: 2, title: "Moderation", description: "Wir vermitteln Moderatoren, die eure Gäste durch eine tolle Feier führen mit Programm, Musik, und Entertainment.", image: "https://cms.goldenlegprojects.com/moderation.jpg" },
                { id: 3, title: "Catering", description: "Essen und Getränke ganz nach euren Wünschen: Snacks, BBQ, Dinner und vieles mehr.", image: "https://cms.goldenlegprojects.com/catering.jpg" },
                { id: 4, title: "Getränke-Pakete", description: "Für jede Feier das richtige Paket und die richtige Menge – passend zu jedem Budget.", image: "https://cms.goldenlegprojects.com/getraenke-paket.jpg" },
                { id: 5, title: "Dekoration", description: "Wir schmücken euer Fest von der Tisch-Deko bis zur gesamten Raumgestaltung.", image: "https://cms.goldenlegprojects.com/dekoration.jpg" },
                { id: 6, title: "Torten", description: "Dein besonderes Fest braucht eine besondere Torte, ganz klar. Wir kennen die besten Konditoren der Stadt.", image: "https://cms.goldenlegprojects.com/torten.jpg" },
                { id: 7, title: "technisches Equipment", description: "Vom Soundsystem über die Lichtanlage bis zu Spezial-Effekten – wir statten euch aus.", image: "https://cms.goldenlegprojects.com/technisches-equipment.jpg" },
                { id: 8, title: "Einlass & Security", description: "Wir organisieren den Empfang und sorgen für zusätzliche Sicherheit.", image: "https://cms.goldenlegprojects.com/security.jpg" },
                { id: 9, title: "Hygiene-Konzept", description: "Mit unseren Hygiene-Konzepten könnt ihr trotz Corona sicher feiern.", image: "https://cms.goldenlegprojects.com/hygiene-konzept.jpg" }
            ]
        }
        this.state = {  }
    }
    render() { 
        return (
            <section id="services" className="line">
                <div className="container">
                    <div className="headline center">
                        <h2>{this.props.headline}</h2>
                    </div>
                    <Services boxes={this.leistungen} />
                </div>
            </section>
        );
    }
}
 
export default Leistungen;