import React, { Component, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

class MenuList extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
        this.menu = this.props.menuItems;
        this.menuActive = this.props.menuActive;
    }
    render() {
        const { links, activeLink } = this.state;
        return (
            <nav id="menu-list" className={this.props.menuActive ? 'active ' : ''}>
                <ul className="menu">
                    {this.menu.map( (menu) =>
                    <li key={menu.id}>
                        <Link
                            to={menu.link}
                        >{menu.title}</Link>
                    </li>
                    )}
                </ul>
            </nav>
        );
    }
}
 
export default MenuList;