import React, { Component } from 'react';

class Fact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            title: this.props.title,
            description: this.props.description
        }
    }
    render() { 
        return (
            <div className="fact-item">
                <div className="fact-value">
                    <span>{this.state.value}</span>
                </div>
                <div className="fact-title">
                    <h2>{this.state.title}</h2>
                </div>
                <div className="fact-description">
                    <p>{this.state.description}</p>
                </div>
            </div>
        );
    }
}
 
export default Fact;