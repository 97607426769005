import React, { Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import '../assets/css/_testimonial.scss';

class Testimonial extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.content = {
            background: this.props.background
        }
    }
    render() { 
        return (
            <section id="testimonials" className="line">
                <div className="container">
                    <div className="headline center">
                        <h2>{this.props.headline}</h2>
                    </div>
                    <div className="testimonial-wrapper">
                        <div className="testimonial-image">
                            <LazyLoadImage className="img-responsive" alt="Reviewer Profile Pic" src={this.content.background} />
                        </div>
                        <div className="testimonial-review">
                            <h2>"Unser Verband feierte eine unvergessliche Firmenfeier - und das über den Dächern Schwerins"</h2>
                            <p><strong>Paul</strong> hat ein Firmenevent mit uns realisiert zum Sommerfest des Unternehmens</p>
                            <p style={{marginTop: 0, marginBotton: 0}}><small>Paul Döring, Bundesstützpunktleiter, Schwerin</small></p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Testimonial;