import React, { Component } from 'react';

class TeamItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            position: this.props.position,
            text: this.props.text,
            image: this.props.image
        }
    }
    render() { 
        return (
            <figure className="team-item">
                <img className="img-responsive" src={this.props.image} alt={this.props.name}/>
                <figcaption>
                    <h2>{this.state.name}</h2>
                    <p className="position">{this.state.position}</p>
                    <p>{this.state.text}</p>
                </figcaption>
            </figure>
        );
    }
}
 
export default TeamItem;