import React, { Component } from 'react';
import Fact from './Fact';
import '../assets/css/_facts.scss';

class Facts extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return (
            <section id="facts" className="line">
                <div className="container">
                    <div className="headline center">
                        <h2>Ein erfolgreiches Jahrzehnt Erfahrung</h2>
                    </div>
                    <div className="facts-wrapper">
                        <Fact value="412" title="Events" description="Über 400 Events in nur 10 Jahren – wir sind stolz diesen Erfarhungsschatz und das unbezahlbare Wissen, von dem unsere Kunden heute profitieren."/>
                        <Fact value="366" title="Kunden" description="So viele Kunden vertrauen uns bereits. Sie ermöglichten uns in den letzten Jahren ein unglaubliches Wachstum. Danke!"/>
                        <Fact value="11" title="Jahre Erfahrung" description="In 2020 ist es ein ganzes Jahrzehnt Erfahrung im Gastro- und Event-Bereich. Noch heute agieren wir mit der selben Power, wie bei der Gründung. "/>
                        <Fact value="22" title="Eigenmarken" description="Wir treffen den Puls der Zeit. Ob erfolgreiche Eigenmarken, oder die Events unserer Kunden – jeder Event ist ein Volltreffer."/>
                        <Fact value="56" title="Locations" description="Wir begannen mit kleinen Events und wuchsen zum Besitz und Management von Restaurants, Clubs und Bars im ganzen Norden. "/>
                        <Fact value="246" title="Partner" description="Wir wählen unsere Partner Weise. Heute zählen über 246 Partner aus Top-Marken und Dienstleistern mit hoher Reputation zu unserem familiären Netzwerk."/>
                    </div>
                </div>
            </section>
        );
    }
}
 
export default Facts;