import React, { Component } from 'react';

class ServiceItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: this.props.title,
            description: this.props.description,
            image: this.props.image
        }
    }
    render() { 
        return (
            <figure key={this.props.id} className="service-item">
                <img className="img-responsive" src={this.props.image} alt={this.props.title}/>
                <figcaption>
                    <h2>{this.props.title}</h2>
                    <p>{this.props.description}</p>
                </figcaption>
            </figure>
        );
    }
}
 
export default ServiceItem;